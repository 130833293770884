import React from 'react';
import Drawer from 'rc-drawer';
import moment from 'moment';
import {
  Intl,
  Form, FormActionsBtns,
  TextField, Select,
  Datepicker,
} from 'components';
import { Col, Row } from 'reactstrap';
import { required } from 'config/InputErrors';
import {
  contactBusinnesStages,
  contactBusinnesStatuses,
} from 'config/Constants/ContactBusinnesStages';
import classNames from 'classnames';

const BusinessEditView = ( {
  open, onClose, data, loading, onSubmit,
} ) => (
  <Drawer
    open={open}
    placement="right"
    handler={false}
    maskClosable
    level={null}
    onClose={onClose}
    className="zindex-drawer"
    width={600}
  >
    <div className={classNames( 'p-4', {
      'app-component-loading': loading,
    } )}
    >
      <h2><Intl id="crm.businnes" /></h2>
      <hr className="mt-0" />
      <Form
        initialValues={{
          ...data,
          closeDate: data.closeDate ? moment( data.closeDate ).format( 'YYYY-MM-DD' ) : moment(),
        }}
        showErrorsInFooter
        onSubmit={onSubmit}
        body={( ) => (
          <>
            <Row className="mb-3">
              <Col md={9}>
                <TextField
                  label="name"
                  field="businessName"
                  placeholder="name"
                  validate={required}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <Select
                  label="crm.businnesStatuses.stage"
                  field="businessStage"
                  placeholder="all"
                  translateOptions
                  options={contactBusinnesStages}
                  validate={required}
                />
              </Col>
              <Col md={6}>
                <Select
                  label="state"
                  field="state"
                  placeholder="all"
                  translateOptions
                  options={contactBusinnesStatuses}
                  validate={required}
                />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md={8}>
                <Datepicker
                  field="closeDate"
                  label="crm.businnesStatuses.closeDate"
                  dateFormat="YYYY-MM-DD"
                  timeFormat={false}
                  validate={required}
                />
              </Col>
            </Row>
          </>
        )}
        footer={<FormActionsBtns onClickCancel={onClose} />}
      />
    </div>
  </Drawer>
);

export default BusinessEditView;
