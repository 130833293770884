import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PermitsView from 'views/Resource/Edit/Permits';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetch, useFetchTableData } from 'hooks';
import EmployeeService from 'api/Employee';
import EmployeePermitsService from 'api/Settings/EmployeePermits';
import FiltersActions from 'store/reducers/Filters';
import moment from 'moment';
import NewPermit from './New';
import ManagePermit from './Manage';

const Permits = ( {
  data,
  entityId,
  toggleLoading,
  toggleErrorAlert,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const [newModalOpen, setNewModalOpen] = useState( false );
  const [manageModalOpen, setManageNewModalOpen] = useState( false );
  const [selectedPermit, setselectedPermit] = useState( '' );
  const [initialPermitStatus, setInitialPermitStatus] = useState( '' );

  const currentYear = moment().year();

  const openNewModal = () => setNewModalOpen( true );
  const closeNewModal = () => {
    setNewModalOpen( false );
    setselectedPermit( '' );
  };
  const toggleManageModal = () => setManageNewModalOpen( !manageModalOpen );

  const [permitOptions, permitOptionsLoading] = useFetch( {
    initialState: { data: [] },
    promise: () => EmployeePermitsService.getEmployeePermits( {
      page: 1,
      elementsPerPage: 10,
      enable: true,
    } ),
    toggleErrorAlert,
  } );

  const [registers, registersLoading] = useFetch( {
    initialState: {},
    promise: () => EmployeeService.getPermitRegisters( entityId, {
      page: 1,
      elementsPerPage: 10,
      year: currentYear,
    } ),
    toggleErrorAlert,
  } );

  const [fetchData, dataTable, dataLoading] = useFetchTableData( {
    promise: ( params ) => EmployeeService.getPermitRequests( entityId, {
      ...params,
      ...params,
      from: `${currentYear}-01-01`,
      till: `${currentYear}-12-31`,
    } ),
    toggleErrorAlert,
    deps: [entityId, dataSubmitted],
  } );

  const reload = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const deletePermitRequest = useDelete( {
    promise: EmployeeService.deletePermitRequest,
    callback: reload,
    toggleLoading,
    toggleErrorAlert,
  } );

  const permitClick = ( id, status ) => {
    setselectedPermit( id );
    setInitialPermitStatus( status );
    toggleManageModal();
  };

  const handleEdit = ( id ) => {
    setselectedPermit( id );
    openNewModal();
  };

  return (
    <>
      <PermitsView
        data={data}
        registers={registers.data}
        settings={permitOptions.data}
        fetchData={fetchData}
        dataTable={dataTable}
        dataLoading={dataLoading
          || permitOptionsLoading
          || registersLoading}
        onNewClick={openNewModal}
        onPermitClick={permitClick}
        onEdit={handleEdit}
        onDelete={deletePermitRequest}
      />
      <NewPermit
        permitId={selectedPermit}
        open={newModalOpen}
        onClose={closeNewModal}
        customerId={entityId}
        reload={reload}
        permitOptions={permitOptions.data}
      />
      <ManagePermit
        open={manageModalOpen}
        onClose={toggleManageModal}
        customerId={entityId}
        reload={reload}
        permitId={selectedPermit}
        initialPermitStatus={initialPermitStatus}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateFilters: FiltersActions.updateFilters,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( null, mapDispatchToProps )( Permits );
