import React from 'react';
import { Container } from 'reactstrap';
import {
  SimpleHeader,
} from 'components';

const Pipelines = () => (
  <>
    <SimpleHeader
      title="crm.pipelines"
    />
    <Container fluid>Pipelines Dashboard (TO DO)</Container>
  </>
);

export default Pipelines;
