import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';
import filterBuilder, { sortByBuilder } from './filterBuilder';

const GET_CONTACTS = gql`
query GetContacts($limit: Int!, $page: Int!, $filters: ContactFiltersInput!, $orderBy: [ContactOrderByInput!]!) {
  Contacts(limit: $limit, page: $page, filters: $filters, orderBy: $orderBy,) {
    ... on BaseError {
      message
    }
    ... on QueryContactsSuccess {
      data {
        data {
          cycle
          email
          id
          name
          owner
          phone
          isActive
        }
        elementsPerPage
        total
      }
    }
  }
}
`;

const getContacts = async ( { page, elementsPerPage, ...rest } ) => {
  const filters = filterBuilder( rest, ['cycle', 'keyword', 'owner', 'email', 'isActive'] );
  const orderBy = sortByBuilder( rest );

  const { data } = await client.query( {
    query: GET_CONTACTS,
    variables: {
      page: page || 1, // pageCurrent,
      limit: elementsPerPage || 10,
      filters,
      orderBy,
    },
    fetchPolicy: 'no-cache',
  } );

  const contacts = data.Contacts.data.data || [];
  const elementsTotal = data.Contacts.data.total || 0;

  if ( data.Contacts.message ) {
    return {
      ok: false,
      message: data.Contacts.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: { data: contacts, elementsTotal, elementsPerPage },
  };
};

export default getContacts;
