import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { formatDataToSend, handlePromise } from 'utils';
import SettingsActions from 'store/reducers/Settings';
import AccommodationService from 'api/Accommodation';
import ArrivalInstructionsView from 'views/Property/RoomType/Accommodation/Edit/ArrivalInstructions';

const ArrivalInstructions = ( {
  data,
  entityId,
  paths,
  parentIds,
  toggleLoading,
  toggleInfoAlert,
  onReloadData,
} ) => {
  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );
      const { documentCheckInInstruction } = formData;
      const dataToSend = formatDataToSend( formData, form );
      const formattedData = { ...dataToSend };
      const propertyId = parentIds ? parentIds.property : formattedData.property;
      const roomTypeId = parentIds ? parentIds.roomType : formattedData.roomType;

      if ( !entityId ) formattedData.enabled = 'true';
      else formattedData.enabled = data.entity.enabled ? 'true' : 'false';

      const [response] = await handlePromise(
        entityId
          ? AccommodationService.updateAccommodation(
            propertyId, roomTypeId, entityId, formattedData,
          )
          : AccommodationService.saveAccommodation(
            propertyId, roomTypeId, formattedData,
          ),
      );

      if ( documentCheckInInstruction.size
        && typeof documentCheckInInstruction === 'object'
      ) {
        await handlePromise(
          AccommodationService.uploadInstructionsFile(
            propertyId, roomTypeId, entityId || response.id, {
              document: documentCheckInInstruction,
            },
          ),
        );
      }
      onReloadData();
      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [
      toggleInfoAlert, toggleLoading, data, entityId, parentIds, onReloadData,
    ],
  );

  return (
    <ArrivalInstructionsView
      name={data.entity ? data.entity.name : ''}
      data={data}
      entityId={entityId}
      parentIds={parentIds}
      paths={paths}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( null, mapDispatchToProps )( ArrivalInstructions );
