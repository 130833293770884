import api from 'config/Api/Api';

const baseEndpoint = '/';

export default {
  sync( data ) {
    return api.post( `${baseEndpoint}/avantio/sync/bulk`, {
      propertyManager: data.propertyManagerId,
      bookingsToUpdate: [
        {
          BookingCode: data.externalId,
          Localizator: data.localizator,
        },
      ],
    } );
  },
};
