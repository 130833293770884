/* eslint-disable no-unused-vars */
import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import CountryService from 'api/Country';
import StateService from 'api/State';
import CityService from 'api/City';
import DashboardCalendarView from 'views/DashboardCalendar';
import EditTask from 'containers/Task/Edit';
import useFetch from 'hooks/useFetch';
import DMTaskScheduleService from 'api/DMTaskSchedule';
import AccommodationService from 'api/Accommodation';
import DMTaskScheduleReportService from 'api/DMTaskScheduleReport';
import SettingsActions from 'store/reducers/Settings';
import IncidenceActions from 'store/reducers/Incidence';
import TaskTypeService from 'api/TaskType';
import PropertyService from 'api/Property';
import RoomTypeService from 'api/RoomType';
import TaskService from 'api/Task';
import useDelete from 'hooks/useDelete';
import { handlePromise } from 'utils';
import BookingBlockedService from 'api/BookingBlocked';
import TagsPredefinedService from 'api/TagsPredefined';
import BookingBlocked from './BookingBlocked';
import BookingOverview from './BookingOverview';

// import { formatData } from './utils';
// eslint-disable-next-line import/named
import {
  formatProperties,
  removeDuplicates,
  formatReports,
  removeDuplicatedAccomodations,
  formatAcc,
} from './utils/format';

const DashboardClendar = ( {
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
  toggleIncidentModal,
  incidenceReloadedAt,
} ) => {
  const dateRange = useRef(
    moment.range(
      moment().startOf( 'day' ),
      moment().startOf( 'day' ).add( 15, 'days' ),
    ),
  );
  const params = useRef( {} );
  const selectedTaskId = useRef();
  const initialAccData = {
    accommodations: {
      elementsTotal: 0,
      elementsPerPage: 0,
      data: [],
    },
    tasks: [],
    bookedAccommodations: [],
    blockedAccommodations: [],
  };
  const [accData, setAccData] = useState( initialAccData );

  const [dataToShow, setDataToShow] = useState( {
    roomTypes: [],
    dateRange: [],
  } );

  const [page, setPage] = useState( 1 );
  const [total, setTotal] = useState( 100 );
  const [propertiesList, setPropertiesList] = useState( [] );
  const [isLoading, setIsLoading] = useState( false );

  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const reloadData = () => setDataSubmitted( new Date().valueOf() );

  const [dataTaskSubmitted, setDataTaskSubmitted] = useState(
    new Date().valueOf(),
  );
  const reloadDataTask = () => setDataTaskSubmitted( new Date().valueOf() );

  const [selectedPropertyId, setSelectedPropertyId] = useState();
  const [selectedBookingId, setSelectedBookingId] = useState();
  const [taskPreloadedData, setTaskPreloadedData] = useState( {} );
  const [blockedPreloadedData, setBlockedPreloadedData] = useState( {} );
  const [selectedAccommodationId, setSelectedAccommodationId] = useState();
  const [selectedRoomTypeId, setSelectedRoomTypeId] = useState();

  const [openBookingBloked, setOpenBookingBloked] = useState( false );
  const toggleModalBookingBloked = () => setOpenBookingBloked( ( state ) => !state );

  const [bookingOverviewOpenModal, setBookingOverviewOpenModal] = useState( false );
  const toggleBookingOverviewOpenModal = () => setBookingOverviewOpenModal( ( state ) => !state );

  const [showReport, setShowReport] = useState( false );

  const [openEditTaskModal, setOpenEditTaskModal] = useState( false );
  const toggleEditTaskModal = () => setOpenEditTaskModal( ( state ) => !state );

  const extractProperty = ( acc ) => ( {
    ...acc,
    property: acc.roomType.property,
  } );

  const [dataP, dataLoadingP] = useFetch( {
    promise: () => ( params.current.property
      ? PropertyService.getProperty( params.current.property )
      : PropertyService.getProperties( {
        page,
        elementsPerPage: 100,
        city: params.current.city,
      } ) ),
    format: ( dataToFormat ) => {
      if ( params.current.property ) {
        return formatProperties( [dataToFormat], dateRange.current );
      }
      setTotal( dataToFormat.elementsTotal );
      setPropertiesList( dataToFormat.data );
      return formatProperties( dataToFormat.data, dateRange.current );
    },
    toggleErrorAlert,
    deps: [dataSubmitted, dataTaskSubmitted, page, incidenceReloadedAt, params.current.city],
    reInit: true,
  } );

  // const [data, dataLoading] = useFetch( {
  //   initialState: {
  //     accommodations: {
  //       elementsTotal: 0,
  //       elementsPerPage: 0,
  //       data: [],
  //     },
  //     tasks: [],
  //     bookedAccommodations: [],
  //     blockedAccommodations: [],
  //   },
  //   promise: () => AccommodationService.getAllAccommodationsByProperty(
  //     params.current.property || 'all',
  //     {
  //       page,
  //       elementsPerPage: 100,
  //     },
  //   ),
  //   format: ( dataToFormat ) => ( {
  //     accommodations: {
  //       ...dataToFormat,
  //       data: dataToFormat.data.map( extractProperty ),
  //     },
  //     tasks: [],
  //     bookedAccommodations: [],
  //     blockedAccommodations: [],
  //   } ),
  //   toggleErrorAlert,
  //   deps: [dataSubmitted, dataTaskSubmitted, page, incidenceReloadedAt],
  //   reInit: true,
  // } );

  // useEffect( () => {
  //   if ( data ) {
  //     setTotal( data.accommodations.elementsTotal );
  //     setAccData( ( prevData ) => ( {
  //       ...prevData,
  //       accommodations: {
  //         ...data.accommodations,
  //         data: removeDuplicatedAccomodations( [
  //           ...prevData.accommodations.data,
  //           ...data.accommodations.data,
  //         ] ),
  //       },
  //     } ) );
  //   }
  // }, [data] );

  useEffect( () => {
    if ( dataP ) {
      setDataToShow( dataP );
    }
    setIsLoading( false );
  }, [dataP] );

  useEffect( () => {
    setDataToShow( formatAcc( dataToShow.roomTypes, accData, dateRange.current ) );
    setIsLoading( false );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accData] );

  const [dataReports, dataReportsLoading] = useFetch( {
    initialState: {},
    promise: () => DMTaskScheduleReportService.getDmTaskSchedules( {
      from: dateRange.current.start
        ? dateRange.current.start.format( 'YYYY-MM-DD' )
        : undefined,
      till: dateRange.current.end
        ? dateRange.current.end.format( 'YYYY-MM-DD' )
        : undefined,
      elementsPerPage:
          dateRange.current
          && dateRange.current.end.diff( dateRange.current.start, 'days' ) + 1,
    } ),
    format: ( dataToFormat ) => formatReports( dataToFormat.data, dateRange.current ),
    toggleErrorAlert,
    deps: [dataSubmitted, dataTaskSubmitted, incidenceReloadedAt],
    reInit: true,
  } );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _get( dataToFormat, 'data', [] );
      const parentTaskTypes = _filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _filter(
          orignalData,
          ( taskType ) => _get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const handleOpenModalNewTask = ( accommodation, date ) => {
    setTaskPreloadedData( {
      accommodation: {
        value: accommodation.accommodationId,
        label: accommodation.name,
      },
      date: moment( `${date} 09:00`, 'YYYY-MM-DD HH:mm' ),
    } );
    selectedTaskId.current = null;
    toggleEditTaskModal();
  };

  const fetchProperties = useCallback(
    ( query ) => PropertyService.getProperties( {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [],
  );

  const mergeData = ( data ) => data.reduce( ( acc, curr ) => {
    // Merge accommodations
    acc.accommodations.elementsTotal += curr.accommodations.elementsTotal;
    acc.accommodations.elementsInPage += curr.accommodations.elementsInPage;
    acc.accommodations.data = acc.accommodations.data.concat( curr.accommodations.data );

    // Merge tasks
    acc.tasks = acc.tasks.concat( curr.tasks );

    // Merge booked accommodations
    acc.bookedAccommodations = acc.bookedAccommodations.concat( curr.bookedAccommodations );

    // Merge blocked accommodations
    acc.blockedAccommodations = acc.blockedAccommodations.concat( curr.blockedAccommodations );

    return acc;
  }, {
    accommodations: {
      elementsTotal: 0,
      elementsInPage: 0,
      data: [],
    },
    tasks: [],
    bookedAccommodations: [],
    blockedAccommodations: [],
  } );

  const fetchBookingsAndTasks = useCallback(
    async ( propertyIds, accommodationIds ) => {
      const forAccommodation = accommodationIds.length > 0;
      const list = forAccommodation ? accommodationIds : propertyIds;

      setIsLoading( true );
      const dataToset = await Promise.all( list.map( async ( elemId ) => {
        const elementToFilter = forAccommodation ? { accommodation: elemId } : { property: elemId };

        const { data: dataBT } = await DMTaskScheduleService.getDmTaskSchedules( {
          page: 1,
          elementsPerPage: total,
          from: dateRange.current.start
            ? dateRange.current.start.format( 'YYYY-MM-DD' )
            : undefined,
          till: dateRange.current.end
            ? dateRange.current.end.format( 'YYYY-MM-DD' )
            : undefined,
          ...params.current,
          ...elementToFilter,
        } );
        return {
          accommodations: {
            ...dataBT.accommodations,
            data: removeDuplicatedAccomodations( [
              ...dataBT.accommodations.data,
            ] ),
          },
          tasks: removeDuplicates( [...dataBT.tasks] ),
          bookedAccommodations: removeDuplicates( [
            ...dataBT.bookedAccommodations,
          ] ),
          blockedAccommodations: removeDuplicates( [
            ...dataBT.blockedAccommodations,
          ] ),
        };
      } ) );
      setAccData( mergeData( dataToset ) );
      setIsLoading( false );
    },
    [total],
  );

  const fetchRoomTypes = useCallback(
    ( query ) => RoomTypeService.getRoomTypes( selectedPropertyId, {
      elementsPerPage: 10,
      keyword: query,
    } ),
    [selectedPropertyId],
  );

  const handleBookingOverviewModal = ( booking ) => {
    setSelectedBookingId( booking.bookingId );
    if ( booking.color === 'blocked' ) {
      toggleModalBookingBloked();
      return;
    }
    if ( booking.color ) {
      // setSelectedBookingId( booking.bookingId );
      toggleBookingOverviewOpenModal();
    }
  };

  const handleOpenModalEditTask = ( task, bookingData ) => {
    const isIncidence = task.type === 'issue';
    if ( isIncidence ) {
      toggleIncidentModal( task.id, {
        bookedAccommodation: _get( bookingData, 'id' ),
        accommodation: {
          value: _get( bookingData, 'currentAccommodation.id' ),
          label: _get( bookingData, 'currentAccommodation.name' ),
        },
      } );
      return;
    }
    setTaskPreloadedData( {
      accommodation: {
        value: _get( bookingData, 'currentAccommodation.id' ),
        label: _get( bookingData, 'currentAccommodation.name' ),
      },
      bookedAccommodation: _get( bookingData, 'id' ),
    } );
    selectedTaskId.current = task.taskId || task.id;
    toggleEditTaskModal();
  };

  const handleShowReport = () => {
    setShowReport( ( state ) => !state );
  };

  const updateDateRangeParams = useCallback( ( date ) => {
    dateRange.current = date;
    setPage( 1 );
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const setFilters = ( filter, value ) => {
    setPage( 1 );

    if ( filter === 'property' ) {
      setSelectedPropertyId( value );
    }
    params.current = { ...params.current, [filter]: value };
    setDataSubmitted( new Date().valueOf() );
  };

  const deleteTask = useDelete( {
    promise: TaskService.deleteTask,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const reloadBookingsAndTasks = useCallback(
    async ( propertyId, taskId ) => {
      setIsLoading( true );
      const { data: dataBT } = await DMTaskScheduleService.getDmTaskSchedules( {
        page: 1,
        elementsPerPage: total,
        from: dateRange.current.start
          ? dateRange.current.start.format( 'YYYY-MM-DD' )
          : undefined,
        till: dateRange.current.end
          ? dateRange.current.end.format( 'YYYY-MM-DD' )
          : undefined,
        ...params.current,
        property: propertyId,
      } );
      setAccData( ( prevData ) => ( {
        accommodations: {
          ...dataBT.accommodations,
          data: removeDuplicates( [
            ...prevData.accommodations.data,
            ...dataBT.accommodations.data,
          ] ),
        },
        tasks: removeDuplicates( [
          ...prevData.tasks.filter( ( tsk ) => tsk.id !== taskId ),
          ...dataBT.tasks,
        ] ),
        bookedAccommodations: removeDuplicates( [
          ...prevData.bookedAccommodations,
          ...dataBT.bookedAccommodations,
        ] ),
        blockedAccommodations: removeDuplicates( [
          ...prevData.blockedAccommodations,
          ...dataBT.blockedAccommodations,
        ] ),
      } ) );
      setIsLoading( false );
    },
    [total],
  );

  const updateDateTask = useCallback(
    async ( propertyId, taskId, date ) => {
      toggleLoading();
      const [errors, response] = await handlePromise(
        TaskService.updateTask( taskId, date ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      reloadBookingsAndTasks( propertyId, taskId );
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [toggleInfoAlert, toggleLoading, reloadBookingsAndTasks],
  );

  const selectToday = () => {
    dateRange.current = moment.range(
      moment().startOf( 'day' ),
      moment().startOf( 'day' ).add( 1, 'day' ),
    );
    setDataSubmitted( new Date().valueOf() );
  };
  const selectThisWeek = () => {
    dateRange.current = moment.range(
      moment().startOf( 'week' ),
      moment().startOf( 'week' ).add( 1, 'week' ),
    );
    setDataSubmitted( new Date().valueOf() );
  };
  const selectThisMonth = () => {
    dateRange.current = moment.range(
      moment().startOf( 'month' ),
      moment().startOf( 'month' ).add( 1, 'month' ),
    );
    setDataSubmitted( new Date().valueOf() );
  };

  const handleOnReloadTask = () => {
    toggleEditTaskModal();
    reloadDataTask();
    reloadData();
  };

  const handleOnClickCell = ( value ) => {
    setSelectedAccommodationId( _get( value, 'accommodationId' ) );
    setSelectedRoomTypeId( _get( value, 'roomTypeId' ) );
    setBlockedPreloadedData( {
      from: moment( _get( value, 'date' ), 'YYYY-MM-DD' ),
      till: moment( _get( value, 'date' ), 'YYYY-MM-DD' ).add( 1, 'day' ),
    } );
    setSelectedBookingId();
    toggleModalBookingBloked();
  };

  const handleOnReloadBookingBlocked = () => {
    toggleModalBookingBloked();
    reloadData();
  };

  const deleteBookingBlocked = useDelete( {
    promise: BookingBlockedService.deleteBookingBlocked,
    callback: reloadData,
    toggleLoading,
    toggleErrorAlert,
  } );

  const nextPage = useCallback( () => {
    if ( !( dataLoadingP || isLoading ) ) {
      if ( total > 100 * page ) {
        setIsLoading( true );
        setPage( ( prevPage ) => prevPage + 1 );
      }
    }
  }, [total, page, dataLoadingP, isLoading] );

  useEffect( () => {
    if ( dataToShow.roomTypes.length < 18 ) {
      nextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToShow] );

  /** countries, states, cities ******************* */
  // COUNTRIES
  const [countryIdSelected, setCountryIdSelected] = useState( null );
  const [countries, countriesLoading] = useFetch( {
    initialState: [],
    promise: CountryService.getCountries,
    toggleErrorAlert,
    reInit: true,
    deps: [],
  } );

  // CITIES DATA
  const [cities, setCities] = useState( [] );
  const [citiesLoading, setCitiesLoading] = useState( false );
  // const [citiesLoaded, setCitiesLoaded] = useState( false );

  // STATES
  const [states, setStates] = useState( [] );
  // const [statesLoaded, setStatesLoaded] = useState( false );
  const [statesLoading, setStatesLoading] = useState( false );
  const fetchStates = useCallback(
    async ( countryId ) => {
      const country = _.find( countries, { id: countryId } );
      if ( !country ) return toggleErrorAlert( 'error' );

      setStates( [] );
      setCities( [] );
      setStatesLoading( true );
      const [errors, response, responseData] = await handlePromise(
        StateService.getStates( country.iso ),
      );
      setStatesLoading( false );

      if ( !response.ok ) return toggleErrorAlert( errors );
      setStates( responseData );
      setCities( [] );
    },
    [countries, toggleErrorAlert],
  );
  const fetchCities = useCallback(
    async ( stateId, countryId ) => {
      const country = _.find( countries, { id: countryId } );
      const state = _.find( states, { id: stateId } );

      if ( !country || !state ) return toggleErrorAlert( 'error' );

      setCities( [] );
      setCitiesLoading( true );
      const [errors, response, responseData] = await handlePromise(
        CityService.getCities( country.iso, state.iso ),
      );
      setCitiesLoading( false );

      if ( !response.ok ) return toggleErrorAlert( errors );
      setCities( responseData );
    },
    [countries, states, toggleErrorAlert],
  );

  const fetchTagsPredefined = useCallback(
    ( ) => TagsPredefinedService.getTagsPredefined(), [],
  );

  const filterByTag = async ( tags ) => {
    toggleLoading( true );
    if ( !tags ) {
      setDataToShow(
        formatProperties( propertiesList, dateRange.current ),
        dateRange.current,
      );
      toggleLoading( false );
      return;
    }
    let _propertiesFiltered = [];
    const [errors, __, responseData] = await handlePromise(
      AccommodationService.getAllAccommodationsAll( {
        elementsPerPage: 500,
        page: 1,
        tags: tags.map( ( item ) => item.value ),
      } ),
    );
    if ( errors ) {
      toggleLoading( false );
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for ( const tag of responseData.data ) {
      const propFoubnd = propertiesList.filter(
        ( propt ) => propt.id === tag.property.id,
      );

      if ( propFoubnd ) {
        if ( tag?._id || tag?.id ) {
          propFoubnd[0].accommodationId = tag?._id || tag?.id;
        }

        _propertiesFiltered = [..._propertiesFiltered, ...propFoubnd];
      }
    }

    setDataToShow(
      formatProperties( _propertiesFiltered, dateRange.current ),
      dateRange.current,
    );
    toggleLoading( false );
  };

  return (
    <>
      <DashboardCalendarView
        dateRange={dateRange.current}
        data={dataToShow}
        isLoading={dataLoadingP || isLoading}
        dataReports={dataReports}
        isLoadingDataReports={dataReportsLoading}
        taskTypes={taskTypes}
        isTaskTypesLoading={loadingTaskTypes}
        onFetchProperties={fetchProperties}
        onfetchRoomTypes={fetchRoomTypes}
        onChangeRange={updateDateRangeParams}
        onContextMenu={handleOpenModalNewTask}
        onClickBooking={handleBookingOverviewModal}
        onClickTask={handleOpenModalEditTask}
        showReport={showReport}
        onClickShowReport={handleShowReport}
        onChangeFilters={setFilters}
        onDeleteTask={deleteTask}
        onDragEnd={updateDateTask}
        onSelectToday={selectToday}
        onSelectThisWeek={selectThisWeek}
        onSelectThisMonth={selectThisMonth}
        selectedPropertyId={selectedPropertyId}
        onDeleteBookingBlocked={deleteBookingBlocked}
        onClickCell={handleOnClickCell}
        nextPage={nextPage}
        onClickAccomodation={fetchBookingsAndTasks}
        resetAccomodation={dataSubmitted}
        onFetchTagsPredefined={fetchTagsPredefined}
        onChangeTag={filterByTag}
        countriesLoading={countriesLoading}
        onChangeCountry={( cId ) => {
          setCountryIdSelected( cId );
          fetchStates( cId );
        }}
        statesLoading={statesLoading}
        onChangeState={( sId ) => {
          fetchCities( sId, countryIdSelected );
        }}
        citiesLoading={citiesLoading}
        optionsGeo={{ countries, states, cities }}
      />

      <BookingOverview
        bookingId={selectedBookingId}
        open={bookingOverviewOpenModal}
        onClose={toggleBookingOverviewOpenModal}
        onOpenTaskEditModal={handleOpenModalEditTask}
        reloadDataBookingTask={dataTaskSubmitted + incidenceReloadedAt}
      />

      <EditTask
        taskId={selectedTaskId.current}
        preloadedData={taskPreloadedData}
        modalOpened={openEditTaskModal}
        onCloseModal={toggleEditTaskModal}
        zIndex={9999}
        onReload={handleOnReloadTask}
        onUpdateFieldTask={reloadDataTask}
      />

      <BookingBlocked
        open={openBookingBloked}
        onClose={toggleModalBookingBloked}
        bookingId={selectedBookingId}
        accommodationId={selectedAccommodationId}
        roomTypeId={selectedRoomTypeId}
        onReload={handleOnReloadBookingBlocked}
        preloadedData={blockedPreloadedData}
      />
    </>
  );
};

const mapStateToProps = ( { incidence } ) => ( {
  incidenceReloadedAt: incidence.reloadedAt,
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleIncidentModal: IncidenceActions.toggleIncidenceEditModal,
};

export default connect( mapStateToProps, mapDispatchToProps )( DashboardClendar );
