import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';

const UPDATE_BUSINESS_OPPORTUNITY = gql`
mutation UpdateBusinessOpportunity($businessName: String!, $businessStage: String!, $closeDate: String!, $id: String!, $state: String!) {
  updateBusinessOpportunity(
    id: $id
    businessName: $businessName
    businessStage: $businessStage
    closeDate: $closeDate
    state: $state
  ) {
    ... on BaseError {
      message
    }
    ... on MutationUpdateBusinessOpportunitySuccess {
      data {
        success
      }
    }
  }
}
`;

const updateBusinessOpportunity = async ( vars ) => {
  const variables = { ...vars };
  delete variables.contact;
  delete variables.contactId;

  const { data } = await client.query( {
    query: UPDATE_BUSINESS_OPPORTUNITY,
    variables,
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateBusinessOpportunity.message ) {
    return {
      ok: false,
      message: data.updateBusinessOpportunity.message,
    };
  }

  const updateSuccessful = data.updateBusinessOpportunity.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default updateBusinessOpportunity;
