import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';

const TOGGLE_CONTACT_ACTIVATION = gql`
mutation ToggleContactActivation($id: String!, $isActive: Boolean!) {
  updateContact(
    id: $id
    isActive: $isActive
  ) {
    ... on BaseError {
      message
    }
    ... on MutationUpdateContactSuccess {
      __typename
      data {
        success
      }
    }
  }
}
`;

const toggleContactActivation = async ( id, isActive ) => {
  const { data } = await client.query( {
    query: TOGGLE_CONTACT_ACTIVATION,
    variables: {
      id,
      isActive,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.updateContact.message ) {
    return {
      ok: false,
      message: data.updateContact.message,
    };
  }

  const updateSuccessful = data.updateContact.data.success || false;

  return {
    ok: updateSuccessful,
    status: 200,
  };
};

export default toggleContactActivation;
