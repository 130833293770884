import React from 'react';
import { Row, Col, Badge } from 'reactstrap';
import moment from 'moment';
import {
  AsyncTable,
  DaterangePickerBtn,
  TableActionsBtns,
  TableGenericFilter,
  TableSelectFilter,
  TableToggler,
  Intl,
} from 'components';
import {
  contactBusinnesStages,
  contactBusinnesStatuses,
} from 'config/Constants/ContactBusinnesStages';
import BusinessEdit from 'containers/CRM/Business/Edit';

const ContactBusinessView = ( {
  data,
  isLoading,
  onFetchData,
  onDeleteContactBusiness,
  businessEditModalOpened,
  toggleBusinessEditModal,
  businnesToEdit,
  onEditBusiness,
  reloadBusinnesOpportunities,
} ) => (
  <>
    <AsyncTable
      id="ContactBusiness"
      data={data.data}
      total={data.elementsTotal}
      isLoading={isLoading}
      onFetchData={onFetchData}
      customFilters={( { filters, setFilter } ) => (
        <Row>
          <Col md={8} lg={4}>
            <TableGenericFilter filters={filters} setFilter={setFilter} />
          </Col>
          <TableToggler />
        </Row>
      )}
      customToggleFilters={( { filters, setFilter, setMultipleFilters } ) => (
        <Row className="ml-0">
          <Col md={5} lg={2} className="pl-0 py-2">
            <TableSelectFilter
              name="businessStage"
              label="crm.businnesStatuses.stage"
              placeholder="all"
              filters={filters}
              isClearable
              options={contactBusinnesStages}
              translateOptions
              // defaultOptions
              // loadOptions={onFetchProperties}
              setFilter={setFilter}
            />
          </Col>
          <Col md={5} lg={2} className="pl-0 py-2">
            <TableSelectFilter
              name="state"
              label="state"
              placeholder="all"
              filters={filters}
              isClearable
              options={contactBusinnesStatuses}
              translateOptions
              // defaultOptions
              // loadOptions={onFetchProperties}
              setFilter={setFilter}
            />
          </Col>
          <Col md={6} lg={4} className="pl-0 py-2">
            <DaterangePickerBtn
              position="left"
              label="crm.businnesStatuses.dateBetween"
              initialValue={moment.range( filters.from || moment().subtract( 7, 'days' ), filters.till || moment().add( 7, 'days' ) )}
              key={filters.from + filters.till}
              input
              onChange={( values ) => {
                setMultipleFilters( {
                  from: moment( values.start ).format( 'YYYY-MM-DD' ),
                  till: moment( values.end ).format( 'YYYY-MM-DD' ),
                } );
              }}
            />
          </Col>
          <Col md={6} lg={4} className="pl-0 py-2">
            <DaterangePickerBtn
              position="left"
              label="crm.businnesStatuses.closeDateBetween"
              initialValue={moment.range( filters.closeDateFrom || moment().subtract( 7, 'days' ), filters.closeDateTill || moment().add( 7, 'days' ) )}
              key={filters.closeDateFrom + filters.closeDateTill}
              input
              onChange={( values ) => {
                setMultipleFilters( {
                  closeDateFrom: moment( values.start ).format( 'YYYY-MM-DD' ),
                  closeDateTill: moment( values.end ).format( 'YYYY-MM-DD' ),
                } );
              }}
            />
          </Col>
        </Row>
      )}
      columns={[

        {
          Header: 'name',
          accessor: 'businessName',
          Cell: ( { cell } ) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              href="#"
              onClick={( e ) => {
                e.preventDefault();
                onEditBusiness( cell.row.original.id );
              }}
            >
              {cell.value}
            </a>
          ),
        },
        {
          Header: 'crm.businnesStatuses.stage',
          accessor: 'businessStage',
          Cell: ( { cell } ) => (
            <Intl id={`crm.businnesStages.${cell.value}`} />
          ),
        },
        {
          Header: 'state',
          accessor: 'state',
          Cell: ( { cell } ) => (
            <Badge color={cell.value === 'active' ? 'success' : 'danger'} pill>
              <Intl id={`crm.businnesStatuses.${cell.value}`} />
            </Badge>
          ),
        },
        {
          Header: 'crm.businnesStatuses.closeDate',
          accessor: 'closeDate',
          Cell: ( { cell } ) => ( cell.value

            ? moment( cell.value ).format( 'YYYY-MM-DD' )
            : '-' ),
        },
        {
          accessor: 'id',
          disableSorting: true,
          Header: null,
          maxWidth: 65,
          Cell: ( { cell } ) => (
            <TableActionsBtns
              actions={[
                {
                  isDelete: true,
                  onClick: () => {
                    onDeleteContactBusiness( cell.row.original.id );
                  },
                },
              ]}
            />
          ),
        },
      ]}
    />
    <BusinessEdit
      open={businessEditModalOpened}
      onClose={toggleBusinessEditModal}
      businnesId={businnesToEdit}
      onReloadData={reloadBusinnesOpportunities}
    />
  </>
);

export default ContactBusinessView;
