import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';

/*
data {
        updatedAt
        phone
        owner
        name
        isActive
        id
        email
        cycle
        createdAt
      }

*/

const GET_BUSINESS_OPPORTUNITY = gql`
query GetBusinessOpportunity($id: String!) {
  BusinessOpportunity(id: $id) {
    ... on BaseError {
      message
    }
    ... on QueryBusinessOpportunitySuccess {     
      data {
        state
        id
        contactId
        contact {
          name
          email
          phone
          owner
          isActive
          cycle
          id
        }
        closeDate
        businessStage
        businessName
      }
    }
  }
}
`;

const getBusinessOpportunity = async ( id ) => {
  const { data } = await client.query( {
    query: GET_BUSINESS_OPPORTUNITY,
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  } );

  const businessOpportunity = data.BusinessOpportunity.data || {};

  if ( data.BusinessOpportunity.message ) {
    return {
      ok: false,
      message: data.BusinessOpportunity.message,
    };
  }

  return {
    ok: true,
    status: 200,
    data: businessOpportunity,
  };
};

export default getBusinessOpportunity;
