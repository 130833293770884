import React, { useState, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import { useDelete, useFetchTableData } from 'hooks';
// import { handlePromise } from 'utils';
import { getBusinnesOpportunities, deleteBusinnesOpportunity } from 'api/CRM/Contact';
import ContactBusinessView from 'views/CRM/Contact/Edit/Business';
import { ContactEditContext } from '../context';

const ContactBusiness = ( {
  toggleLoading, toggleErrorAlert,
} ) => {
  const {
    contactId,
  } = useContext( ContactEditContext );

  const [businnesToEdit, setBusinessToEdit] = useState( null );

  const [businessEditModalOpened, setBusinessEditModalOpened] = useState( false );

  const [
    onReloadBusinnesOpportunities,
    setOnReloadBusinnesOpportunities,
  ] = useState( new Date().valueOf() );
  const reloadBusinnesOpportunities = useCallback( () => {
    setBusinessToEdit( null );
    setOnReloadBusinnesOpportunities( new Date().valueOf() );
  }, [] );

  const [fetchData, data, dataLoading] = useFetchTableData( {
    promise: ( params ) => {
      const name = params.keyword ? { businessName: params.keyword } : {};
      return getBusinnesOpportunities( { ...params, ...name, contactId } );
    },
    toggleErrorAlert,
    conditional: !!contactId,
    deps: [contactId, onReloadBusinnesOpportunities],
    // deps: [onReloadContacts],
  } );

  const onDeleteBusinnesOpportunity = useDelete( {
    promise: deleteBusinnesOpportunity,
    callback: () => {
      reloadBusinnesOpportunities();
    },
    toggleLoading,
    toggleErrorAlert,
  } );
  //

  const toggleBusinessEditModal = useCallback( ( ) => {
    setBusinessEditModalOpened( ( v ) => !v );
  }, [] );
  const onEditBusiness = useCallback( ( id ) => {
    setBusinessToEdit( id );
    toggleBusinessEditModal();
  }, [toggleBusinessEditModal] );

  return (
    <ContactBusinessView
      data={data}
      isLoading={dataLoading}
      onFetchData={fetchData}
      onDeleteContactBusiness={onDeleteBusinnesOpportunity}
      businessEditModalOpened={businessEditModalOpened}
      toggleBusinessEditModal={toggleBusinessEditModal}
      businnesToEdit={businnesToEdit}
      onEditBusiness={onEditBusiness}
      reloadBusinnesOpportunities={reloadBusinnesOpportunities}
    />
  );
};

const mapDispatchToProps = ( {
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
} );

export default connect( null, mapDispatchToProps )( ContactBusiness );
