import { gql } from '@apollo/client';
import client from 'config/Api/CrmApi';

const DELETE_BUSINNES_OPPORTUNITY = gql`
mutation DeleteBusinessOpportunity($id: String!) {
  deleteBusinessOpportunity(id: $id) {
    ... on BaseError {
      message
    }
    ... on MutationDeleteBusinessOpportunitySuccess {
      data {
        success
      }
    }
  }
}
`;

const deleteBusinnesOpportunity = async ( id ) => {
  const { data } = await client.query( {
    query: DELETE_BUSINNES_OPPORTUNITY,
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  } );

  if ( data.deleteBusinessOpportunity.message ) {
    return {
      ok: false,
      message: data.deleteBusinessOpportunity.message,
    };
  }
  const deleteSuccessful = data.deleteBusinessOpportunity.data.success || false;

  return {
    ok: deleteSuccessful,
    status: 200,
  };
};

export default deleteBusinnesOpportunity;
