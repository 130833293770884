import React from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import BusinessEditView from 'views/CRM/Business/Edit';
import { useFetch, useSubmit } from 'hooks';
import { getBusinessOpportunity, updateBusinessOpportunity } from 'api/CRM/Contact';

const BusinessEdit = ( {
  businnesId, open, onClose, toggleErrorAlert, toggleLoading,
  toggleInfoAlert, onReloadData,
} ) => {
  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => getBusinessOpportunity( businnesId ),
    toggleErrorAlert,
    conditional: !!businnesId,
    deps: [businnesId],
  } );

  const onSubmit = useSubmit( {
    promise: ( dataToSend ) => updateBusinessOpportunity( { ...dataToSend, id: businnesId } ),
    toggleLoading,
    toggleInfoAlert,
    callback: ( ) => {
      onClose( );
      if ( onReloadData ) onReloadData();
    },
    deps: [businnesId],
  } );

  return (
    <BusinessEditView
      open={open}
      onClose={onClose}
      data={data}
      loading={dataLoading}
      onSubmit={onSubmit}
    />
  );
};

const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( BusinessEdit );
