// import _ from 'lodash';

import General from 'containers/Property/RoomType/Accommodation/Edit/General';
import Wifi from 'containers/Property/RoomType/Accommodation/Edit/Wifi';
import AmenityInventory from 'containers/Property/RoomType/Accommodation/Edit/AmenityInventory/Dashboard';
import AmenityRecord from 'containers/Property/RoomType/Accommodation/Edit/AmenityRecords/Dashboard';
import CommunityRule from 'containers/Property/RoomType/Accommodation/Edit/CommunityRule/Dashboard';
import TravelReport from 'containers/Property/RoomType/Accommodation/Edit/TravelReport';
// import CustomField from 'containers/Property/RoomType/Accommodation/Edit/CustomField';
import Smartlockers from 'containers/Property/RoomType/Accommodation/Edit/Smartlockers';
import RoomExpenses from 'containers/Property/RoomType/Accommodation/Edit/RoomExpenses/Dashboard';
import Rates from 'containers/Property/RoomType/Accommodation/Edit/Rates/Dashboard';
import Bookings from 'containers/Property/RoomType/Accommodation/Edit/Bookings/Dashboard';
import ArrivalInstructions from 'containers/Property/RoomType/Accommodation/Edit/ArrivalInstructions';
import Landlord from 'containers/Property/RoomType/Accommodation/Edit/Landlord';

export default [
  {
    name: 'general',
    path: '',
    component: General,
    exact: true,
  },
  {
    name: 'accommodation.landlord',
    path: '/landlord',
    component: Landlord,
  },
  {
    name: 'communityRules',
    path: '/community-rules',
    component: CommunityRule,
  },
  // {
  //   name: 'smartLockerLegacy',
  //   path: '/smart-locker-legacy',
  //   component: CustomField,
  //   extraData: {
  //     type: 'customFields',
  //     filter: ( { category } ) => category === 'smartlockers',
  //     title: 'smartLocker',
  //   },
  // },
  {
    name: 'smartLocker',
    path: '/smart-locker',
    component: Smartlockers,
    extraData: {
      filter: ( { category } ) => category === 'smartlockers',
      title: 'smartLocker',
    },
  },
  {
    name: 'Wifi',
    path: '/wifi',
    component: Wifi,
  },
  // {
  //   name: 'travelPlugin',
  //   path: '/travel-plugin',
  //   component: CustomField,
  //   extraData: {
  //     type: 'customFields',
  //     filter: { category: 'estr' },
  //     title: 'travelPlugin',
  //   },
  // },
  {
    name: 'travelReport',
    path: '/travelReport',
    component: TravelReport,
  },
  {
    name: 'expenses',
    path: '/expenses',
    component: RoomExpenses,
  },
  {
    name: 'inventory',
    path: '/inventory',
    component: AmenityInventory,
  },
  {
    name: 'amenityRecords',
    path: '/amenity-records',
    component: AmenityRecord,
  },
  // TODO: PMS
  {
    name: 'rates',
    path: '/rates',
    component: Rates,
  },
  {
    name: 'bookings',
    path: '/bookings',
    component: Bookings,
  },
  {
    name: 'arrivalInstructions',
    path: '/arrivalInstructions',
    component: ArrivalInstructions,
  },
  //-----
  // {
  //   name: 'customFields',
  //   path: '/custom-fields',
  //   component: CustomField,
  //   extraData: {
  //     type: 'customFields',
  //     filter: ( item ) => !_.startsWith( item.name, 'homeselect_communityRule' )
  //       && item.category !== 'estr'
  //       && item.category !== 'smartlockers',
  //     title: 'customFields',
  //   },
  // },
];
